<template>
  <div class="wrapper">

    <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks">
      <user-menu></user-menu>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>


        <!-- cookie policy modal -->
        <modal :show.sync="modals.cookiePolicy"
                 footerClasses="justify-content-center"
                 type="notice">
          <h3 slot="header" class="modal-title">Cookie Policy</h3>
          <cookie-policy class="tc-privacy-modal"></cookie-policy>
          <div slot="footer" class="justify-content-center">
                 <p-button type="info" round @click.native="modals.cookiePolicy = false">Close</p-button>
          </div>

        </modal>


        <!-- privacy notice modal -->
        <modal :show.sync="modals.privacyNotice"
                 footerClasses="justify-content-center"
                 type="notice">
          <h3 slot="header" class="modal-title">Privacy Notice</h3>
          <privacy-notice class="tc-privacy-modal"></privacy-notice>
          <div slot="footer" class="justify-content-center">
                 <p-button type="info" round @click.native="modals.privacyNotice = false">Close</p-button>
          </div>

        </modal>


        <!-- terms conditions modal -->
        <modal :show.sync="modals.termsConditions"
                 v-if="store.mmData ? store.mmData.user.display_tc : store.mmData"
                 class="mm-terms"
                 footerClasses="justify-content-center"
                 type="notice">
          <h3 slot="header" class="modal-title">Terms & Conditions</h3>
          <terms-conditions :toggleUserConfirmation="toggleUserConfirmation" :userConfirm="userConfirm" class="tc-privacy-modal"></terms-conditions>
          <div slot="footer" class="justify-content-center">
                 <p-button type="info" v-if="!userConfirm" disabled round>Agree</p-button>
                 <p-button type="info" v-else round @click.native="confirmMundialTerms">Agree</p-button>
          </div>

        </modal>
        <modal :show.sync="modals.termsConditions"
                 v-else
                 footerClasses="justify-content-center"
                 type="notice">
          <h3 slot="header" class="modal-title">Terms & Conditions</h3>
          <terms-conditions :toggleUserConfirmation="toggleUserConfirmation" :userConfirm="userConfirm" class="tc-privacy-modal"></terms-conditions>
          <div slot="footer" class="justify-content-center">
                 <p-button type="info" round @click.native="modals.termsConditions = false">Close</p-button>
          </div>

        </modal>

        <!-- loading modal -->
        <modal :show.sync="store.mmLoader"
                 footerClasses="justify-content-center"
                 class="mm-terms mm-loader-modal"
                 type="notice">
          <h3 slot="header" class="modal-title"><mundial-loader-text></mundial-loader-text></h3>
          <mundial-loader></mundial-loader>
          <h3 slot="footer" class="modal-title">Mundial Media</h3>
        </modal>

      <content-footer :toggleCookie="toggleCookie"
                      :togglePrivacy="togglePrivacy"
                      :toggleTerms="toggleTerms"></content-footer>


    </div>
  </div>
</template>
<script>
  import { Button, Modal } from 'src/components/UIComponents';
  import TopNavbar from './TopNavbar.vue'
  import CookiePolicy from 'src/components/Dashboard/CookiePolicy'
  import PrivacyNotice from 'src/components/Dashboard/PrivacyNotice'
  import TermsConditions from 'src/components/Dashboard/TermsConditions'
  import MundialLoader from 'src/components/Dashboard/MundialLoader'
  import MundialLoaderText from 'src/components/Dashboard/MundialLoaderText'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import UserMenu from 'src/components/UIComponents/SidebarPlugin/UserMenu.vue'
  import { store } from 'src/store'

  export default {
    components: {
      CookiePolicy,
      PrivacyNotice,
      TermsConditions,
      MundialLoader,
      MundialLoaderText,
      TopNavbar,
      ContentFooter,
      DashboardContent,
      UserMenu,
      Modal,
      [Button.name]: Button
    },
    methods: {
      async confirmMundialTerms() {
          const tcForm = {};
          tcForm.user_id = this.store.mmData.user.id
          tcForm.confirmed = true

          await this.store.confirmTC(tcForm)
          this.modals.termsConditions = false

          if (this.$route.path !== "/mundial/overview") { this.$router.push({'name':'Overview'}) }
      },
      toggleUserConfirmation(bool) {
        this.userConfirm = bool
      },
      toggleCookie() {
        this.modals.cookiePolicy = !this.modals.cookiePolicy
      },
      togglePrivacy() {
        this.modals.privacyNotice = !this.modals.privacyNotice
      },
      toggleTerms() {
        this.modals.termsConditions = !this.modals.termsConditions
      },
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      }
    },
    data() {
      return {
        store,
        userConfirm: false,
        modals: {
          cookiePolicy: false,
          privacyNotice: false,
          termsConditions: false
        }
      }
    },
    mounted () {
      if (this.store.mmData.user.display_tc) {
        this.modals.termsConditions = true
      } else {
        this.userConfirm = true
      }
    },
    beforeDestroy() {
      this.modals.cookiePolicy = false
      this.modals.privacyNotice = false
      this.modals.termsConditions = false
    }
  }

</script>
<style>
.mm-terms {
  pointer-events: none !important;
}
.mm-terms button.close {
  display: none !important;
}
</style>
