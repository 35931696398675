<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">Create Mundial User</h4>
    </div>
    <div class="card-body">
      <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createProfile)" autocomplete="off">
        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="Company"
                      :disabled="true"
                      placeholder="Mundial Media">
            </fg-input>
          </div>


          <!-- OG field
          <div class="col-md-3">
            <ValidationProvider
              name="username"
              rules="required|min:2"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      autocomplete="off"
                      :error="failed ? 'The Username field is required': null"
                      :hasSuccess="passed"
                      name="username"
                      label="Username (required)"
                      placeholder="Username"
                      v-model="user.username">
              </fg-input>
            </ValidationProvider>
          </div>
          END -->


          <div class="col-md-6">
            <ValidationProvider
              name="email"
              rules="required|email|min:5"
              v-slot="{ passed, failed }"
            >
              <fg-input type="email"
                      autocomplete="off"
                      :error="failed ? 'The Email field is required': null"
                      :hasSuccess="passed"
                      name="email"
                      label="Email (required)"
                      placeholder="Email"
                      v-model="user.email">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <ValidationProvider
              name="firstname"
              rules="min:2"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      autocomplete="off"
                      :error="failed ? 'First Name must be at least 2 characters': null"
                      :hasSuccess="passed"
                      name="firstname"
                      label="First Name"
                      placeholder="First Name"
                      v-model="user.first_name">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider
              name="lastname"
              rules="min:1"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      autocomplete="off"
                      :error="failed ? 'Last Name must be at least 1 character': null"
                      :hasSuccess="passed"
                      name="lastname"
                      label="Last Name"
                      placeholder="Last Name"
                      v-model="user.last_name">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>



        <!-- KEPT FOR REFERENCE synced pw confirm
        <div class="row">
          <div class="col-md-6">
            <ValidationProvider
              name="password"
              rules="required|min:5"
              v-slot="{ passed, failed }"
              vid="confirmation"
            >
              <fg-input type="password"
                      autocomplete="off"
                      :error="failed ? 'The Password field is required': null"
                      :hasSuccess="passed"
                      name="password"
                      label="Password (required)"
                      placeholder="Current Password..."
                      v-model="user.password">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <ValidationProvider
              name="confirmpassword"
              rules="required|min:5|confirmed:confirmation"
              v-slot="{ passed, failed }"
            >
              <fg-input type="password"
                      autocomplete="off"
                      :error="failed ? 'Passwords do not match': null"
                      :hasSuccess="passed"
                      name="confirmpassword"
                      label="Confirm Password (required)"
                      placeholder="Confirm Password..."
                      v-model="user.confirm_password">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        END -->



        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill btn-wd">
            Create Profile
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);

  export default {
    data () {
      return {
        store,
        user: {
          mundialprofile: 1,
          is_staff: 1,
          username: '',
          email: '',
          first_name: '',
          last_name: '',
        }
      }
    },
    methods: {
      async createProfile () {
        const userDetails = {};
        userDetails.mundialprofile = 1
        userDetails.is_staff = this.user.is_staff
        userDetails.username = this.user.email
        userDetails.email = this.user.email
        userDetails.first_name = this.user.first_name
        userDetails.last_name = this.user.last_name

        //console.log('Your data: ' + JSON.stringify(userDetails))

        const boolCheck = await this.store.createMundial(userDetails)
        if (boolCheck === 'EXISTING_USERNAME') {
            alert('USERNAME already exists!')
        } else if (boolCheck) {
            this.$router.push({'name':'Overview'})      
            alert('Mundial User created successfully!')
        } else {
            alert('USERNAME or PASSWORD is incorrect!')
        }
      },
    },
    mounted () {
      if (store.mmData.sessionFilters) store.mmData.sessionFilters = false
    },
  }

</script>
<style>

</style>
